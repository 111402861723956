import { useState } from "react";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import proyect from "./exports/proyects";
import SliderProyect from "./sharedComponents/SliderProyect";

const Proyect = () => {
  const [sliderPosition, setSliderPosition] = useState(0);
  const cantidad = proyect?.length;
  const nextImage = () => {
    setSliderPosition(sliderPosition === cantidad - 1 ? 0 : sliderPosition + 1);
  };

  const afterImage = () => {
    setSliderPosition(sliderPosition === 0 ? cantidad - 1 : sliderPosition - 1);
  };

  return (
    <section
      className=" flex flex-col relative md:h-[80vw] lg:h-[70vw] xl:h-[60vw] w-full  2xl:h-[50vw]"
      id="proyect">
      {proyect.map((proyt, index) => (
        <div
          key={index}
          className={`w-full transition-opacity ${
            sliderPosition === index
              ? "ease-out duration-500 opacity-100"
              : "ease-in duration-500 opacity-0"
          }`}>
          {sliderPosition === index && (
            <SliderProyect
              key={index}
              img1={proyt.img}
              img2={proyt.img}
              titulo={proyt.title}
              descripcion={proyt.text}
            />
          )}
        </div>
      ))}

      <div className="mt-5 flex  bg- md:ml-36 absolute md:top-[80%] top-[100%] transform -translate-y-1/2 z-10 items-center justify-center">
        <BsArrowLeftCircle
          className="mx-10 2xl:w-[60px] 2xl:h-[60px] w-[50px] h-[50px]"
          onClick={afterImage}
        />
        <BsArrowRightCircle
          onClick={nextImage}
          className="2xl:w-[60px] 2xl:h-[60px] w-[50px] h-[50px]"
        />
      </div>
    </section>
  );
};

export default Proyect;
