import React from 'react'

const SliderProyect = ({img1, img2, titulo, descripcion}) => {
  return (
    <div className="md:w-full max-[500px]:w-[500px] flex flex-col md:flex-row lg:py-20 md:p-14 justify-center lg:h-full md:h-[500px] mx-10">
      <img
        src={img1}
        alt="proyect"
        className="w-[100%] md:w-[80%]  md:mt-0 mt-10 rounded-t-2xl md:hidden object-cover h-[50vw]"
      />
      <div className="bg-white  w-[100%] md:w-[50%]  p-10 lg:h-[60vw] xl:h-[50vw] md:h-[65vw] lg:w-[50vw] 2xl:h-[40vw]">
        <div className="gotham ">
          <h1 className="bg-[#F5F5F5] px-1 inline-block text-[#E7193F] font-bold ">
            Servicios
          </h1>
          <p className=" text-black font-extrabold  lg:text-4xl md:text-2xl text-xl">
            {titulo}
          </p>
        </div>
        <div className="lg:pt-8 gotham">
          <p className="text-[14px] lg:text-[16px] xl:text-[20px] 2xl:text-[22px] text-justify">
            {descripcion}
          </p>
        </div>
      </div>
      <img
        src={img2}
        alt="proyect"
        className="w-[100%] md:w-[50%]  md:h-[65vw] lg:h-[60vw] xl:h-[50vw] md:mt-0 mt-10 hidden md:flex object-fill  2xl:h-[40vw] "
      />
    </div>
  );
}

export default SliderProyect