import servicio1 from "../../assets/img/1.jpeg";
import servicio2 from "../../assets/img/1-1.jpeg";
import servicio3 from "../../assets/img/2.jpeg";
import servicio4 from "../../assets/img/2-2.jpeg";
import servicio5 from "../../assets/img/3.jpeg";
import servicio6 from "../../assets/img/3-3.jpeg";

const services = [
  {
    imgs: [servicio1, servicio2],
    titulo: "Construcción",
    text: "Restauraciones locativas o ampliaciones de planta, con una gran infraestructura para el desarrollo de sus operaciones.",
    textservice:
      "• Servicio 1 <br/> • Servicio 1 <br/> • Servicio 2 <br/> • Servicio 3 <br/> • Servicio 4 <br/>",
  },
  {
    imgs: [servicio3, servicio4],
    titulo: "Adecuaciones",
    text: "Reorganizamos los espacios dentro de construcciones, para adaptarlos a un nuevo uso o necesidades específicas.",
    textservice:
      "• Servicio 1 <br/> • Servicio 1 <br/> • Servicio 2 <br/> • Servicio 3 <br/> • Servicio 4 <br/>",
  },
  {
    imgs: [servicio5, servicio6],
    titulo: "Mantenimiento Locativo",
    text: "Nos encargamos del lavado de fachadas en empresas, con equipo de maquinaria industrial a presión.",
    textservice:
      "• Servicio 1 <br/> • Servicio 1 <br/> • Servicio 2 <br/> • Servicio 3 <br/> • Servicio 4 <br/>",
  },
];

export default services;
