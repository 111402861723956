import React from "react";
import ServicioBox from "./sharedComponents/ServicioBox";
import SliderServices from "./sharedComponents/SliderServices";
import services from "./exports/services";
import SwiperCore, { EffectCards, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "@mui/material";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import "swiper/css/autoplay";


const Servicios = () => {
  const isMdScreen = useMediaQuery(
    "(min-width: 768px) and (max-width: 1024px)"
  );
  const isSmScreen = useMediaQuery("(max-width: 768px)");

  let slidesPerView = 3;

  if (isMdScreen) {
    slidesPerView = 2;
  }

  if (isSmScreen) {
    slidesPerView = 1;
  }

  return (
    <section
      className="flex flex-col justify-center items-center  mb-20 w-full"
      id="service">
      <h1 className="gotham-Black text-3xl md:text-4xl lg:text-5xl font-bold text-center my-10">
        Nuestros Servicios
      </h1>

      <div className=" md:w-[70vw] lg:w-[80vw] w-[60vw] flex justify-center items-center xl:w-[90%]">
        <Swiper
          className="flex items-center justify-center"
          modules={[EffectCards]}
          slidesPerView={slidesPerView}
          spaceBetween={10}>
          {services.map((servicio, index) => (
            <SwiperSlide key={index}>
              <ServicioBox
                imgs={servicio.imgs}
                titulo={servicio.titulo}
                text={servicio.text}
                textservice={servicio.textservicio}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Servicios;
