import servicio1 from "../../assets/img/1.jpeg";
import servicio3 from "../../assets/img/2.jpeg";
import servicio5 from "../../assets/img/3.jpeg";
const proyect = [
  {
    img: servicio1,
    title: "Servicio de construcción",
    text: "Ofrecemos un servicio integral de construcción que abarca desde la planificación hasta la ejecución y entrega del proyecto final. Contamos con un equipo altamente capacitado y especializado en diversas áreas de la construcción, lo que nos permite ofrecer un servicio de alta calidad y garantizar la satisfacción del cliente. Desde edificios residenciales y comerciales hasta estructuras industriales, nuestro equipo está listo para llevar a cabo proyectos de cualquier escala.",
  },


  {
    img: servicio3,
    title: "Servicio de adecuaciones",
    text: "Nuestro servicio de adecuaciones es perfecto para aquellos clientes que necesitan renovar o actualizar sus espacios existentes. Ya sea que necesite una renovación completa o una actualización parcial, nuestro equipo está capacitado para llevar a cabo cualquier proyecto de adecuación con eficiencia y calidad. Desde la actualización de instalaciones eléctricas y sanitarias hasta la remodelación completa de interiores, podemos ayudarlo a transformar su espacio en algo nuevo y mejorado..",
  },


  {
    img: servicio5,
    title: "Servicio de mantenimiento locativo",
    text: "Ofrecemos un servicio completo de mantenimiento que abarca desde la inspección y reparación de instalaciones existentes hasta la implementación de mejoras para aumentar la eficiencia y reducir costos a largo plazo. Nuestro equipo está capacitado para llevar a cabo cualquier tarea de mantenimiento, desde la reparación de estructuras hasta la actualización de sistemas de seguridad. Ya sea que necesite un servicio de mantenimiento regular o una intervención puntual, estamos aquí para ayudarlo a mantener sus instalaciones en perfecto estado. ",
  },

];

export default proyect