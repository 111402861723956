import { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

import SwiperCore, { EffectCards, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import "swiper/css/autoplay";

const ServicioBox = ({ imgs, titulo, text, textservice }) => {
  const [verServicio, setVerServicio] = useState(false);
  const initialImgs = imgs || [];
  const [imagenes, setImagenes] = useState(initialImgs);

 
  SwiperCore.use([EffectCards, Autoplay]);

  return (
    <div
      className="container gotham w-[340px] md:w-72 relative lg:mx-32 my-5  flex justify-center items-center transition-all shadow-lg rounded-3xl"
      style={{
        height: verServicio ? "500px" : "400px",
        transition: "all 0.5s ease-in-out",
      }}>
      <div
        className="h-60 w-full absolute bottom-0 bg-gradient-to-b from-transparent rounded-2xl via-gray-100 to-white transition-all"
        style={{
          height: verServicio ? "350px" : "250px",
          transition: "all 0.5s ease-in-out",
        }}></div>
      <div className="h-full w-full relative">
        {/* Agrega una posición relativa para contener los elementos */}
        <div
          className="z-10 absolute inset-0 flex flex-col items-center justify-center text-[15px] px-5 transition-all"
          style={{
            top: verServicio ? "calc(85% - 140px)" : "50%",
            transition: "all 0.5s ease-in-out",
            fontSize: verServicio ? "18px" : "25px",
          }}>
          <h1 className="font-extrabold text-center">
            {verServicio ? "Servicio" : titulo}
          </h1>
          <p
            className="text-sm tracking-tighter w-full text-[#ABABAB] transition-all"
            style={{
              textAlign: verServicio ? "left" : "center",
              transition: "all 0.5s ease-in-out",
              paddingLeft: verServicio ? "10px" : "0",
            }}
            dangerouslySetInnerHTML={{
              __html: verServicio ? textservice : text,
            }}></p>
        </div>
        <Swiper
          modules={[EffectCards, Autoplay]}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          loop={true}
          className="z-0"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}>
          {imagenes.map((img, index) => (
            <SwiperSlide key={index}>
              <img
                src={img}
                alt={`servicio-${index}`}
                className="h-[90%] w-full object-cover rounded-3xl"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ServicioBox;
